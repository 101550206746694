
import store from "@/store/index";
import { ServerError } from "@common/errors";
import { IServerRES } from "@common/server";
import { IStandardTime, IProductStandardTime, IIngredientStandardTime, isIngredientStandardTime, isProductStandardTime } from "@common/standardTime";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import Vue from "vue";
import _ from "lodash";
import * as XLSX from "xlsx";

interface ProductExcel {
	"ID": string;
	"Produs": string;
	"Mise en place (secunde)": number;
	"Asamblare (secunde)": number;
	"Variabil (secunde)": number;
}

interface IngredientExcel {
	"ID": string;
	"Temp": string;
	"Ingredient": string;
	"UM/BUC": number;
	"UM/BAX": number;
	"Timp Aducere": number;
	"Timp Decongelare": number;
	"Timp Fix Pregatire Ingredient": number;
	"QNT Minima Pregatire": number;
	"Timp Variabil Pregatire Ingredient": number;
	"/ QNT Pregatire": number;
	"Timp Fix Gatire": number;
	"QNT Minima Gatire": number;
	"Timp Variabil Gatire": number;
	"/ QNT Gatire": number;
	"Timp Racire": number;
}

export default Vue.extend({
	name: "StandardTimes",
	data: () => {
		return {
			snackbar: {
				show: false as boolean,
				text: "" as string,
				color: "primary" as string,
			},
			fileUploaded: null as File | null,
			standardTimes: [] as IStandardTime[],
			loading: false as boolean
		};
	},
	computed: {
		products () {
			const productSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const product of this.store.getters.products) {
				const item = {
					text: `${product.title}`,
					value: product.id
				};

				if (_.findIndex(productSelectItems, item) === -1) {
					productSelectItems.push(item);
				}
			}

			return productSelectItems;
		},
		ingredients () {
			const ingredientSelectItems: {
				text: string;
				value: string;
			} [] = [];

			for (const ingredient of this.store.getters.ingredients) {
				const item = {
					text: `${ingredient.name}`,
					value: ingredient.id
				};

				if (_.findIndex(ingredientSelectItems, item) === -1) {
					ingredientSelectItems.push(item);
				}
			}

			return ingredientSelectItems;
		}
	},
	async created () {
		this.store.dispatch.changeAppTitle("Standard Times");

		if (this.store.getters.products.length === 0) {
			await this.store.dispatch.fetchProducts();
		}

		if (this.store.getters.ingredients.length === 0) {
			await this.store.dispatch.fetchIngredients();
		}
	},
	methods: {
		async fetchData () {
			this.loading = true;

			try {
				const options: AxiosRequestConfig = {
					method: "GET",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/standard-times`,
				};
				const res: AxiosResponse<IServerRES<IStandardTime[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.standardTimes = res.data.payload;
				}
			} catch (err) {
				console.error(err);

				this.showSnackbar("error", "Failed to fetch data");

				return false;
			}

			this.loading = false;

			return true;
		},
		async exportDatabase () {
			if (!await this.fetchData()) return;

			const productsExcel: ProductExcel[] = [];

			const ingredientsExcel: IngredientExcel[] = [];

			for (const standardTime of this.standardTimes) {
				if (standardTime.productId !== undefined && standardTime.productId !== null) {
					productsExcel.push({
						ID: standardTime.productId,
						Produs: this.products.find((product) => product.value === standardTime.productId)?.text || "",
						"Mise en place (secunde)": standardTime.miseEnPlace || 0,
						"Asamblare (secunde)": standardTime.preparationTime,
						"Variabil (secunde)": standardTime.variablePreparationTime,
					});
				} else if (standardTime.ingredientId !== undefined && standardTime.ingredientId !== null) {
					ingredientsExcel.push({
						ID: standardTime.ingredientId,
						Temp: standardTime.temperature || "",
						Ingredient: this.ingredients.find((ingredient) => ingredient.value === standardTime.ingredientId)?.text || "",
						"UM/BUC": standardTime.umBuc || 0,
						"UM/BAX": standardTime.umBax || 0,
						"Timp Aducere": standardTime.bringTime || 0,
						"Timp Decongelare": standardTime.defrostTime || 0,
						"Timp Fix Pregatire Ingredient": standardTime.preparationTime || 0,
						"QNT Minima Pregatire": standardTime.minimumCookingQnt || 0,
						"Timp Variabil Pregatire Ingredient": standardTime.variablePreparationTime || 0,
						"/ QNT Pregatire": standardTime.preparationDivisionUnit || 0,
						"Timp Fix Gatire": standardTime.cookingTime || 0,
						"QNT Minima Gatire": standardTime.minimumCookingQnt || 0,
						"Timp Variabil Gatire": standardTime.variableCookingTime || 0,
						"/ QNT Gatire": standardTime.cookingDivisionUnit || 0,
						"Timp Racire": standardTime.coolingTime || 0,
					});
				}
			}

			const productsSheet = XLSX.utils.json_to_sheet(productsExcel);
			const ingredientsSheet = XLSX.utils.json_to_sheet(ingredientsExcel);

			const book = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(book, productsSheet, "Produse");
			XLSX.utils.book_append_sheet(book, ingredientsSheet, "Ingrediente");

			XLSX.writeFile(book, "standard-times.xlsx");
		},
		onPickFile () {
			if (this.$refs.fileInput) {
				(this.$refs.fileInput as HTMLInputElement).click();
			}
		},
		onFileChange (event: Event) {
			const target = event.target as HTMLInputElement;
			const file = target.files?.[0];

			if (file) {
				this.fileUploaded = file;
			}
		},
		async importData () {
			this.loading = true;
			let fileToUpload: string | ArrayBuffer | null = null;

			const fileReader = new FileReader();
			fileReader.addEventListener("load", () => {
				fileToUpload = fileReader.result;
			});

			if (this.fileUploaded) {
				fileReader.readAsArrayBuffer(this.fileUploaded);
			}

			fileReader.addEventListener("loadend", () => {
				if (fileToUpload) {
					this.xlsxToData(fileToUpload as ArrayBuffer);
				} else {
					this.showSnackbar("error", "Invalid file format");
					this.loading = false;
				}
			});
		},
		async xlsxToData (bytes: ArrayBuffer) {
			const workbook = XLSX.read(bytes, { type: "array" });

			const productsSheet = workbook.Sheets.Produse;
			const ingredientsSheet = workbook.Sheets.Ingrediente;

			if (productsSheet === undefined || ingredientsSheet === undefined || !productsSheet || !ingredientsSheet) {
				return this.showSnackbar("error", "Invalid file format");
			}

			const products: ProductExcel[] = XLSX.utils.sheet_to_json(productsSheet);
			const ingredients: IngredientExcel[] = XLSX.utils.sheet_to_json(ingredientsSheet);

			const standardTimes: IStandardTime[] = [];

			for (const product of products) {
				const standardTime: IProductStandardTime = {
					productId: product.ID,
					miseEnPlace: product["Mise en place (secunde)"],
					preparationTime: product["Asamblare (secunde)"],
					variablePreparationTime: product["Variabil (secunde)"],
					minimumPreparationQnt: 1,
					preparationDivisionUnit: 1,
				};

				if (isProductStandardTime(standardTime)) {
					standardTimes.push(standardTime);
				} else {
					return this.showSnackbar("error", "File is invalid: Product standard time format");
				}
			}

			for (const ingredient of ingredients) {
				const standardTime: IIngredientStandardTime = {
					ingredientId: ingredient.ID,
					temperature: ingredient.Temp,
					umBuc: ingredient["UM/BUC"],
					umBax: ingredient["UM/BAX"],
					bringTime: ingredient["Timp Aducere"],
					defrostTime: ingredient["Timp Decongelare"],
					preparationTime: ingredient["Timp Fix Pregatire Ingredient"],
					minimumPreparationQnt: ingredient["QNT Minima Pregatire"],
					variablePreparationTime: ingredient["Timp Variabil Pregatire Ingredient"],
					preparationDivisionUnit: ingredient["/ QNT Pregatire"],
					cookingTime: ingredient["Timp Fix Gatire"],
					minimumCookingQnt: ingredient["QNT Minima Gatire"],
					variableCookingTime: ingredient["Timp Variabil Gatire"],
					cookingDivisionUnit: ingredient["/ QNT Gatire"],
					coolingTime: ingredient["Timp Racire"],
				};

				if (isIngredientStandardTime(standardTime)) {
					standardTimes.push(standardTime);
				} else {
					return this.showSnackbar("error", "File is invalid: Ingredient standard time format");
				}
			}

			try {
				const options: AxiosRequestConfig = {
					method: "POST",
					headers: {
						Authorization: `Bearer ${localStorage.getItem("token")}`
					},
					url: `${store.getters.serverURL}/standard-times`,
					data: standardTimes,
				};
				const res: AxiosResponse<IServerRES<IStandardTime[]>> = await axios(options);

				if (res.data.err === ServerError.NO_ERROR) {
					this.standardTimes = res.data.payload;
				}
			} catch (err) {
				console.error(err);

				this.showSnackbar("error", "Failed to import data");
				return false;
			}

			this.loading = false;
			this.showSnackbar("success", "Data imported successfully");
			this.removeFile();
		},
		showSnackbar (color: string, text: string) {
			if (color === "error") {
				this.removeFile();
				this.loading = false;
			}

			this.snackbar.show = true;
			this.snackbar.color = color;
			this.snackbar.text = text;
		},
		removeFile () {
			this.fileUploaded = null;
			if (this.$refs.fileInput) {
				(this.$refs.fileInput as HTMLInputElement).value = "";
			}
		}
	},
});
