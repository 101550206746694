export interface IStandardTime {
    id?: string;
    productId?: string;
    ingredientId?: string;
    temperature?: string;
    umBuc?: number;
    umBax?: number;
    bringTime?: number;
    defrostTime?: number;
    preparationTime: number;
    minimumPreparationQnt: number;
    variablePreparationTime: number;
    preparationDivisionUnit: number;
    cookingTime?: number;
    minimumCookingQnt?: number;
    variableCookingTime?: number;
    cookingDivisionUnit?: number;
    coolingTime?: number;
    miseEnPlace?: number;
}

export interface IProductStandardTime {
    id?: string;
    productId: string;
    preparationTime: number;
    minimumPreparationQnt: number;
    variablePreparationTime: number;
    preparationDivisionUnit: number;
    miseEnPlace: number;
}

export interface IIngredientStandardTime {
    id?: string;
    ingredientId: string;
    temperature: string;
    umBuc?: number;
    umBax?: number;
    bringTime: number;
    defrostTime: number;
    preparationTime: number;
    minimumPreparationQnt: number;
    variablePreparationTime: number;
    preparationDivisionUnit: number;
    cookingTime: number;
    minimumCookingQnt: number;
    variableCookingTime: number;
    cookingDivisionUnit: number;
    coolingTime: number;
}

export function isStandardTime (obj: any): boolean {
    const isPreparationTime = obj.preparationTime !== undefined && obj.preparationTime !== null;
    const isMinimumPreparationTime = obj.minimumPreparationTime !== undefined && obj.minimumPreparationTime !== null;
    const isVariablePreparationTime = obj.variablePreparationTime !== undefined && obj.variablePreparationTime !== null;
    const isPreparationDivisionUnit = obj.preparationDivisionUnit !== undefined && obj.preparationDivisionUnit !== null;

    return isPreparationTime && isMinimumPreparationTime && isVariablePreparationTime && isPreparationDivisionUnit;
}

export function isProductStandardTime (obj: any): boolean {
    const isProductId = obj.productId !== undefined && obj.productId !== null;
    const isPreparationTime = obj.preparationTime !== undefined && obj.preparationTime !== null;
    const isMinimumPreparationQnt = obj.minimumPreparationQnt !== undefined && obj.minimumPreparationQnt !== null;
    const isVariablePreparationTime = obj.variablePreparationTime !== undefined && obj.variablePreparationTime !== null;
    const isPreparationDivisionUnit = obj.preparationDivisionUnit !== undefined && obj.preparationDivisionUnit !== null;
    const isMiseEnPlace = obj.miseEnPlace !== undefined && obj.miseEnPlace !== null;

    return isProductId && isPreparationTime && isMinimumPreparationQnt && isVariablePreparationTime && isPreparationDivisionUnit && isMiseEnPlace;
}

export function isIngredientStandardTime (obj: any): boolean {
    const isIngredientId = obj.ingredientId !== undefined && obj.ingredientId !== null;
    const isTemperature = obj.temperature !== undefined && obj.temperature !== null;
    const isBringTime = obj.bringTime !== undefined && obj.bringTime !== null;
    const isDefrostTime = obj.defrostTime !== undefined && obj.defrostTime !== null;
    const isPreparationTime = obj.preparationTime !== undefined && obj.preparationTime !== null;
    const isMinimumPreparationQnt = obj.minimumPreparationQnt !== undefined && obj.minimumPreparationQnt !== null;
    const isVariablePreparationTime = obj.variablePreparationTime !== undefined && obj.variablePreparationTime !== null;
    const isPreparationDivisionUnit = obj.preparationDivisionUnit !== undefined && obj.preparationDivisionUnit !== null;
    const isCookingTime = obj.cookingTime !== undefined && obj.cookingTime !== null;
    const isMinimumCookingQnt = obj.minimumCookingQnt !== undefined && obj.minimumCookingQnt !== null;
    const isVariableCookingTime = obj.variableCookingTime !== undefined && obj.variableCookingTime !== null;
    const isCookingDivisionUnit = obj.cookingDivisionUnit !== undefined && obj.cookingDivisionUnit !== null;
    const isCoolingTime = obj.coolingTime !== undefined && obj.coolingTime !== null;

    return isIngredientId && isTemperature && isBringTime && isDefrostTime && isPreparationTime && isMinimumPreparationQnt && isVariablePreparationTime && isPreparationDivisionUnit && isCookingTime && isMinimumCookingQnt && isVariableCookingTime && isCookingDivisionUnit && isCoolingTime;
}
