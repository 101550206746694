import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"d-flex justify-center"},[_c(VCard,{attrs:{"width":"20em"}},[_c(VCardTitle,[_vm._v("Standard Times Excel")]),_c(VCardText,[_c(VRow,{staticClass:"ma-4",attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.exportDatabase}},[_vm._v("Export database")])],1),_c(VRow,{staticClass:"ma-4",attrs:{"justify":"center"}},[_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"primary"},on:{"click":_vm.onPickFile}},[_vm._v("Import database")]),_c('input',{ref:"fileInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":".xlsx"},on:{"change":_vm.onFileChange}}),(_vm.fileUploaded)?_c('span',{staticClass:"ml-4 mt-4",attrs:{"color":"primary"}},[_vm._v("File uploaded: "+_vm._s(_vm.fileUploaded.name))]):_vm._e()],1),_c(VRow,{staticClass:"ma-4",attrs:{"justify":"center"}},[(_vm.fileUploaded)?_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"success"},on:{"click":_vm.importData}},[_vm._v("Save")]):_vm._e()],1)],1)],1),_c(VSnackbar,{attrs:{"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c(VBtn,_vm._b({attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.show = false}}},'v-btn',attrs,false),[_c(VIcon,[_vm._v("clear")])],1)]}}]),model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }